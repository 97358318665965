//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: { type: String, default: '' },
    data: { type: [Number, String], default: '' },
    isLoading: { type: Boolean, default: false },
  },
};
