//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        dateFrom: '',
        dateTo: '',
        cumulativeCurrencyId: 0,
      },
    };
  },

  computed: {
    ...mapState('dashboard', ['filterLists']),
    ...mapGetters('currencies', ['getCurrencyByTitle']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
    const currency = this.getCurrencyByTitle(PRICE_EQUIVALENT_CURRENCY_LIST[0]);
    const { id } = currency || {};
    this.filters.cumulativeCurrencyId = id;
  },

  methods: {
    ...mapActions('dashboard', ['setFilters']),

    setYesterday() {
      const dateFrom = new Date();
      dateFrom.setDate(dateFrom.getDate() - 1); // set dateFrom as one day ago
      this.filters.dateFrom = moment(dateFrom).format('YYYY-MM-DD');

      const dateTo = new Date();
      this.filters.dateTo = moment(dateTo).format('YYYY-MM-DD');
    },

    setToday() {
      const dateFrom = new Date();
      this.filters.dateFrom = moment(dateFrom).format('YYYY-MM-DD');
      this.filters.dateTo = '';
    },

    set24hours() {
      const dateFrom = new Date();
      dateFrom.setDate(dateFrom.getDate() - 1); // set dateFrom as one day ago
      this.filters.dateFrom = moment(dateFrom);

      const dateTo = new Date();
      this.filters.dateTo = moment(dateTo);
    },
  },
};
