var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"dashboard__table"},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-0 dashboard__system-status"},[_c('SystemStatus',{attrs:{"data":_vm.financeStatus,"currency-title":_vm.additionalData.cumulativeCurrencyTitle,"is-loading":_vm.generalLoader || _vm.loader}})],1),_c('v-col',{staticClass:"flex-grow-0 dashboard__gross-profit"},[_c('GrossProfit',{attrs:{"data":_vm.grossProfitFull,"currency-title":_vm.additionalData.cumulativeCurrencyTitle,"is-loading":_vm.generalLoader || _vm.loader}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"flex-grow-0 dashboard__users"},[_c('Users',{attrs:{"data":_vm.usersStats,"is-loading":_vm.generalLoader || _vm.loader}})],1),_c('v-col',{staticClass:"flex-grow-0 dashboard__users"},[_c('Card',{attrs:{"title":this.$t('Verification requests'),"is-loading":_vm.generalLoader || _vm.loader}},[_c('router-link',{attrs:{"to":{
              name: 'users',
              query: {
                verificationStatusList: _vm.constant.user.VERIFICATION_STATUS.VERIFICATION_REQUEST,
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.totalVerificationRequests)+" ")])],1),_c('Card',{attrs:{"title":this.$t('Rejected verification requests'),"is-loading":_vm.generalLoader || _vm.loader}},[_c('router-link',{attrs:{"to":{
              name: 'users',
              query: {
                verificationStatusList: _vm.constant.user.VERIFICATION_STATUS.REJECTED,
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.usersStats.verificationRequest ? _vm.usersStats.verificationRequest.rejected : '')+" ")])],1),_c('Card',{attrs:{"title":this.$t('Suspicious accounts'),"is-loading":_vm.generalLoader || _vm.loader}},[_c('router-link',{attrs:{"to":{
              name: 'users',
              query: {
                warnings: true,
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.usersWithWarnings)+" ")])],1)],1),_c('v-col',{staticClass:"flex-grow-0 dashboard__users"},[_c('Card',{attrs:{"title":this.$t('Deposit requests'),"is-loading":_vm.generalLoader || _vm.loader}},[_c('router-link',{attrs:{"to":{
              name: 'transactions',
              query: {
                historyOperationTypeList: [_vm.constant.operations.OPERATION_TYPE.TRANSACTION],
                historySide: _vm.constant.operations.OPERATION_SIDE.IN,
                historyTransactionStatusList: [
                  _vm.constant.operations.TRANSACTION_STATUS.ADMIN_CHECK,
                  _vm.constant.operations.TRANSACTION_STATUS.AWAITING_MANUAL_CHECK,
                  _vm.constant.operations.TRANSACTION_STATUS.NEED_CONTACT_SUPPORT ],
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.transactionStats.reduce(function (a, c) { return a + c.depositCount; }, 0))+" ")])],1),_c('Card',{attrs:{"title":this.$t('Withdraw requests'),"is-loading":_vm.generalLoader || _vm.loader}},[_c('router-link',{attrs:{"to":{
              name: 'transactions',
              query: {
                historyOperationTypeList: [_vm.constant.operations.OPERATION_TYPE.TRANSACTION],
                historySide: _vm.constant.operations.OPERATION_SIDE.OUT,
                historyTransactionStatusList: [
                  _vm.constant.operations.TRANSACTION_STATUS.ADMIN_CHECK,
                  _vm.constant.operations.TRANSACTION_STATUS.AWAITING_MANUAL_CHECK,
                  _vm.constant.operations.TRANSACTION_STATUS.NEED_CONTACT_SUPPORT ],
              },
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.transactionStats.reduce(function (a, c) { return a + c.withdrawalCount; }, 0))+" ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }