/* eslint-disable */

export const chartConfig = [
  {
    title: 'Trading',
    color: '#3f75e3',
    key: 'trades',
  },
  {
    title: 'P2P',
    color: '#64c7e8',
    key: 'p2P',
  },
  {
    title: 'Merchant',
    color: '#ffe484',
    key: 'merchant',
  },
  {
    title: 'Withdraw / deposit',
    color: '#eb77d4',
    key: 'transactions',
  },
];
