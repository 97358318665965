//
//
//
//
//
//

import Dashboard from '~/views/Dashboard/Dashboard/Dashboard.vue';

export default {
  components: {
    Dashboard,
  },
};
