//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import Filters from './DashboardFilters.vue';
import SystemStatus from './DashboardSystemStatus.vue';
import GrossProfit from './DashboardGrossProfit.vue';
import P2P from './DashboardP2P.vue';
import Users from './DashboardUsers.vue';
import Card from './DashboardCard.vue';
import constant from '~/const';

export default {
  components: {
    Filters,
    SystemStatus,
    GrossProfit,
    P2P,
    Users,
    Card,
  },

  data() {
    return {
      loader: false,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),

    ...mapState('dashboard', [
      'financeStatus',
      'grossProfitFull',
      'usersStats',
      'transactionStats',
      'additionalData',
      'p2pStatistic',
    ]),

    totalVerificationRequests() {
      const { usersStats } = this;
      if (usersStats.verificationRequest) {
        const verification = usersStats.verificationRequest;
        return verification.total - (verification.rejected + verification.confirmed);
      }
      return 0;
    },

    usersWithWarnings() {
      const { usersStats } = this;
      return usersStats.usersWithWarnings || 0;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
    ]),
    ...mapActions('dashboard', {
      loadFinanceStatusAction: 'loadFinanceStatus',
      loadGrossProfitFullAction: 'loadGrossProfitFull',
      loadUsersStatsAction: 'loadUsersStats',
      loadTransactionStatsAction: 'loadTransactionStats',
      loadP2pStatisticAction: 'loadP2pStatistic',
    }),

    async loadData() {
      this.setGeneralProgress(true);
      this.loader = true;
      await Promise.allSettled([
        this.loadFinanceStatusAction(),
        this.loadGrossProfitFullAction(),
        this.loadUsersStatsAction(),
        this.loadTransactionStatsAction(),
        this.loadP2pStatisticAction(),
      ]);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },
  },
};
