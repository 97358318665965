//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { chartConfig as chartConfigConst } from './grossProfitConfig';

export default {
  props: {
    data: { type: Object, required: true },
    currencyTitle: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters('currencies', ['toCurrencyFormat']),

    chartConfig: () => chartConfigConst,

    preparedData() {
      const { chartConfig, data, total } = this;
      return chartConfig.map((e) => ({
        title: e.title,
        color: e.color,
        value: ((data[e.key] * 100) / total).toFixed(),
      }));
    },

    total() {
      const {
        p2P,
        trades,
        transactions,
        merchant,
      } = this.data;
      return p2P + trades + transactions + merchant;
    },
  },

  watch: {
  },

  methods: {
  },
};
