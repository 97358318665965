//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as d3 from 'd3';
import { mapGetters } from 'vuex';

export default {
  props: {
    data: { type: Array, required: true },
    currencyTitle: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters('currencies', ['toCurrencyFormat']),

    totalData() {
      const { data } = this;
      // TODO: заменить на cumulative
      const balanceUserTotal = data.reduce((a, c) => a + c.balanceUser, 0);
      const fundsDepositsTotal = data.reduce((a, c) => a + c.fundsDeposits, 0);
      const fundsWithdrawalsTotal = data.reduce((a, c) => a + c.fundsWithdrawals, 0);
      const fundsOrdersTotal = data.reduce((a, c) => a + c.fundsOrders, 0);
      return {
        balanceUserTotal,
        fundsDepositsTotal,
        fundsWithdrawalsTotal,
        fundsOrdersTotal,
      };
    },

    chartConfig() {
      return [
        {
          currencyTitle: 'PZM',
          color: '#967de3',
        },
        {
          currencyTitle: 'BTC',
          color: '#3f75e3',
        },
        {
          currencyTitle: 'ETH',
          color: '#64c7e8',
        },
        {
          currencyTitle: 'LTC',
          color: '#eb77d4',
        },
        {
          currencyTitle: this.$t('Others'),
          color: '#ececec',
          isOthers: true,
        },
      ];
    },

    preparedData() {
      const { data, chartConfig } = this;
      const { balanceUserTotal } = this.totalData;
      const preparedData = chartConfig.map((e) => ({ ...e, value: 0 }));
      const others = preparedData.find((c) => c.isOthers);
      data.forEach((e) => {
        const currency = preparedData.find((c) => c.currencyTitle === e.curencyTitle);
        if (currency) {
          currency.value += e.balanceUser; // TODO: заменить на cumulative
        } else {
          others.value += e.balanceUser; // TODO: заменить на cumulative
        }
      });
      return preparedData.map((e) => ({
        ...e,
        percentValue: ((e.value / balanceUserTotal) * 100).toFixed(),
      }));
    },
  },

  watch: {
    async isLoading(val) {
      await this.$nextTick();
      if (!val) this.drawChart();
    },
  },

  methods: {
    drawChart() {
      const { preparedData } = this;

      const data = preparedData.map((e) => e.percentValue);
      const colors = preparedData.map((e) => e.color);

      const canvas = this.$refs.chart;
      const context = canvas.getContext('2d');

      const { width, height } = canvas;
      const radius = Math.min(width, height) / 2;

      const arc = d3.arc()
        .outerRadius(radius)
        .innerRadius(radius - 8)
        .context(context);

      const pie = d3.pie();

      const arcs = pie(data);

      context.translate(width / 2, height / 2);

      arcs.forEach((d, i) => {
        context.beginPath();
        arc(d);
        context.fillStyle = colors[i];
        context.fill();
      });
    },
  },
};
